import React, { Component } from 'react';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import { AxiosError } from 'axios';

// @movember
import { MoThemeProvider, Spinner } from '@movember/mo-gel';

// Styles
import { GlobalStyle, StyledSpinnerContainer } from './App.styled';

// Components
import { Questionnaire } from './components/questionnaire/questionnaire';
import Authenticate from './components/authenticate/authenticate';
import AuthenticationError from './components/authenticationError/authenticationError';

import { GroupedResults } from './components/groupedResutls/groupedResults';
import { Redir, PrivateRoute } from './components/privateRoute/privateRoute';

// Services
import BrandService from './services/brandService';

// Constants
import {
  HEADER_TOKEN_KEY,
  BRAND_NAME,
  COMPLETE_STATUS,
  PCORANZ_PARTICIPANT_FOLLOWUP,
  PCORANZ_PARTICIPANT_BASELINE
} from './constants';

// Models
import { ErrorState } from './models/errorState';

interface AppProps {
  apiCalls: (brand: string, token: string | null) => Promise<any>;
}

interface AppState {
  questionnaireType: string;
  questions: Array<any>;
  answers: any;
  errorState: ErrorState;
  isLoading: boolean;
  brandName: string;
  jurisdiction: string;
}

export class App extends Component<AppProps & RouteComponentProps<any>, AppState> {
  constructor(props: AppProps & RouteComponentProps<any>) {
    super(props);
    this.state = {
      questionnaireType: '',
      questions: [],
      answers: null,
      errorState: { occured: false, status: 200, message: '' },
      isLoading: true,
      brandName: this.resolveBrandName(),
      jurisdiction: ''
    };
  }

  async componentDidMount() {
    const { brandName } = this.state;
    const { apiCalls } = this.props;

    const brand = BrandService.getBrand(brandName);

    const { searchParams } = new URL(document.location.href);

    // If the token query param is passed, then we use that as the token
    // else we get the token from sessionStorage if it's available
    const token = searchParams.get('token') || sessionStorage.getItem(HEADER_TOKEN_KEY);

    await apiCalls(brandName, token)
      .then((data: any) => {
        let questionnaireKey = '';
        if (data[0] === 'followup') {
          questionnaireKey = PCORANZ_PARTICIPANT_FOLLOWUP;
        }
        if (data[0] === 'baseline') {
          questionnaireKey = PCORANZ_PARTICIPANT_BASELINE;
        }
        this.setState({
          questionnaireType: questionnaireKey,
          questions: data[1],
          answers: data[2],
          jurisdiction: data[2].jurisdiction,
          isLoading: false
        });
      })
      .catch((error: AxiosError) => {
        // If there is any catched error, set the error state accordingly
        if (error.response) {
          this.setState({
            errorState: { occured: true, status: error.response.status, message: error.response.statusText }
          });
        }
      });

    // If there's no token we remove the loading state to show an error page
    this.setState({
      isLoading: false
    });
  }

  resolveBrandName = (): string => {
    let brand = new URL(document.location.href).searchParams.get('brand');
    if (BrandService.isBrandName(brand)) {
      return brand as string;
    }
    brand = sessionStorage.getItem(BRAND_NAME);
    if (BrandService.isBrandName(brand)) {
      return brand as string;
    }
    return BrandService.defaultBrandName;
  };

  render() {
    const { questionnaireType, questions, answers, errorState, isLoading, brandName, jurisdiction } = this.state;
    const brand = BrandService.getBrand(brandName);
    const { colors } = brand.theme;

    return (
      <MoThemeProvider theme={brand.theme}>
        <GlobalStyle />
        {isLoading ? (
          <StyledSpinnerContainer>
            <Spinner color={colors.actionPrimary700} />
          </StyledSpinnerContainer>
        ) : (
          <Switch>
            <Route exact path="/" render={Redir} />
            <PrivateRoute
              exact
              path="/results"
              render={() => <GroupedResults questions={questions} answers={answers} />}
            />
            <PrivateRoute
              exact
              path="/question/:id/:child?"
              render={(props: any) =>
                (answers.status !== COMPLETE_STATUS && (
                  <Questionnaire
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    questionnaireType={questionnaireType}
                    questions={questions}
                    answers={answers}
                    jurisdiction={jurisdiction}
                  />
                )) || <Route path="/" render={Redir} />
              }
            />
            <Route
              path="/auth"
              render={() => <Authenticate questions={questions} answers={answers} errorState={errorState} />}
            />
            <Route path="/error" render={() => <AuthenticationError errorState={errorState} />} />
          </Switch>
        )}
      </MoThemeProvider>
    );
  }
}

export default withRouter(App);
