export const HEADER_TOKEN_KEY = 'eprom-token';
export const BRAND_NAME = 'brand';

export const SKIPPED = 'Declined to answer';
export const SKIPPED_CODE = '-1';

// For muti choice questions
export const YES_DISPLAY = 'Yes';
export const YES_CODE = '1';
export const NO_DISPLAY = 'No';
export const NO_CODE = '0';
export const COMORBIDITY_TITLE = 'Comorbidities';

// For the parent conditional questions, these are the codes for Yes and No
export const PARENT_YES = '1';
export const PARENT_NO = '2';
export const NO_OF_CONDITIONAL_QUESTIONS = 5;

export const COMPLETE_STATUS = 'completed';
export const NONE_OPTION = 'None of the above';

// PROM types
export const EPIC26_PLUS_QUESTIONNAIRE = 'epic26-plus-questionnaire';
export const EPIC26_QUESTIONNAIRE = 'epic26-questionnaire';
export const MO_TC_26 = 'mo-tc-26';
export const PCORANZ_PARTICIPANT_BASELINE = 'pcoranz-participant-baseline';
export const PCORANZ_PARTICIPANT_FOLLOWUP = 'pcoranz-participant-followup';

// Jurisdiction names
export const JURISDICTION_NAMES: { [key: string]: string } = {
  VIC: 'PCOR-Vic',
  NSW: 'PCCR',
  QLD: 'PCOR-QLD',
  TAS: 'PCOR-TAS',
  NT: 'PCOR-NT',
  ACT: 'PCOR-ACT',
  WA: 'PCOR-WA',
  SA: 'PCOR-PCCOC'
};

// TODO: Make this an array instead
export const QUESTION_INDEX_MAPPER: { [key: string]: string } = {
  0: 'a',
  1: 'b',
  2: 'c',
  3: 'd',
  4: 'e',
  5: 'f',
  6: 'g',
  7: 'h',
  8: 'i',
  9: 'j',
  10: 'k',
  11: 'l',
  12: 'm',
  13: 'n',
  14: 'o',
  15: 'p',
  16: 'q',
  17: 'r',
  18: 's'
};
